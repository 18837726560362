import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { DashboardService } from '../../services/dashboard.service';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'projectName' | 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  costModal: string | undefined = undefined;
  totalProjectRecords: number = 0;
  activeProjectLength: number = 0;
  completedProjectLength: number = 0;
  holdProjectLength: number = 0;
  totalOpenPositionRecords: number = 0;
  totalAccount: number = 0;
  openPositionList: any[] = [];
  successfulProjectPercentage: number = 0;
  ongoingProjectPercentage: number = 0;
  onHoldProjectPercentage: number = 0;
  totalProjects: number = 0;
  totalRevenue: number = 0;
  totalExpense: number = 0;
  totalProfit: number = 0;
  loading: boolean = false;

  constructor(
    private router: Router,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.fetchFinanceData();
    this.fetchProjectStatus();
    this.fetchOpenPositionLength();

    this.revenueAnalyticsByMonth();
    this.revenueAnalytics();
    this.revenueAndProfit();
  }

  revenueAnalyticsByMonth(): void {
    // this.loading = true;
    this.dashboardService.getRevenuePerTeam().subscribe({
      next: (res: any) => {
        const categories = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const coreRevenueData = Array(12).fill(0);
        const projectRevenueData = Array(12).fill(0);

        if (res.statusCode === 200 && res.data.length) {
          const apiData = res.data;
          apiData.forEach((item: any) => {
            const monthIndex = new Date(item.month).getMonth();
            coreRevenueData[monthIndex] =
              Math.round(item.coreRevenue * 100) / 100;
            projectRevenueData[monthIndex] =
              Math.round(item.projectRevenue * 100) / 100;
            this.loading = false;
          });
        } else {
          console.error('Failed to fetch Revenue data');
          // this.loading = false;
        }

        Highcharts.chart('chart-xy-line', {
          legend: {
            itemStyle: {
              color: '#666D79',
              fontSize: '10px',
            },
          },
          chart: {
            type: 'column',
          },
          title: {
            text: 'Revenue Analytics with sales & profit (USD)',
            align: 'left',
          },
          credits: {
            enabled: false,
          },
          xAxis: {
            lineColor: '#C5D4DB',
            labels: {
              style: {
                fontSize: '12px',
              },
            },
            categories: categories,
          },
          yAxis: {
            lineWidth: 1,
            lineColor: '#C5D4DB',
            gridLineWidth: 0.6,
            gridLineDashStyle: 'dot',
            title: {
              enabled: false,
            },
            labels: {
              format: '${value}',
              style: {
                fontSize: '12px',
              },
            },
          },
          plotOptions: {
            column: {
              borderRadius: 20,
              borderWidth: 0,
            },
          },
          series: [
            {
              name: 'Core Team Revenue',
              data: coreRevenueData.map((value, index) => ({
                name: categories[index],
                y: value,
                color: '#30A4DC',
                pointPadding: 0,
              })),
              color: '#30A4DC',
            },
            {
              name: 'Project Team Revenue',
              data: projectRevenueData.map((value, index) => ({
                name: categories[index],
                y: value,
                color: '#BEEAFF',
                pointPadding: 0,
              })),
              color: '#BEEAFF',
            },
          ],
        } as any);
        this.loading = false;
      },
      error: (error) => {
        console.error('API error', error);
        this.loading = false;
      },
    });
  }

  revenueAnalytics(): void {
    this.dashboardService.getRevenuePerAccount().subscribe({
      next: (res: any) => {
        if (res.statusCode === 200) {
          const categories = res.data.map((account: any) => account.name);
          const seriesData = res.data.map((account: any) => ({
            y: Math.round(account.totalRevenue * 100) / 100,
            color: this.getColorForRevenue(account.totalRevenue),
          }));

          Highcharts.chart('chart-horizontal-line', {
            chart: {
              type: 'bar',
              // height: 350,
            },
            title: {
              text: '',
            },
            xAxis: {
              lineColor: '#C5D4DB',
              labels: {
                style: {
                  fontSize: '12px',
                  color: '#1D1D1D',
                },
              },
              categories: categories,
              title: {
                text: '',
              },
              gridLineWidth: 0,
            },
            yAxis: {
              lineColor: '#C5D4DB',
              title: {
                text: '',
              },
              labels: {
                format: '${value}',
                style: {
                  fontSize: '12px',
                  color: '#1D1D1D',
                },
              },
              lineWidth: 1,
              gridLineWidth: 0,
              girdLineStyle: 'dot',
            },
            credits: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                borderRadius: '54px',
                pointWidth: 12,
                dataLabels: {
                  enabled: false,
                },
                groupPadding: 0.2,
              },
            },
            series: [
              {
                type: 'bar',
                showInLegend: false,
                data: seriesData,
              } as Highcharts.SeriesBarOptions,
            ],
          } as Highcharts.Options);
        } else {
          console.error('Failed to fetch revenue data');
        }
      },
      error: (error) => {
        console.error('API error', error);
      },
    });
  }

  getColorForRevenue(revenue: number): string {
    return revenue > 50000 ? '#30A4DC' : '#BEEAFF';
  }

  revenueAndProfit(): void {
    this.dashboardService.getRevenueAndProfitPerMonth().subscribe({
      next: (res: any) => {
        let categories = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        let revenueData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let profitData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        if (res.statusCode === 200 && res.data.length) {
          const apiCategories = res.data.map((item: any) =>
            new Date(item.month).toLocaleString('default', { month: 'short' })
          );

          const apiRevenueData = res.data.map((item: any) => item.totalRevenue);
          const apiProfitData = res.data.map((item: any) => item.totalProfit);

          // Update categories and data based on API response
          apiCategories.forEach((month: string, index: string | number) => {
            const monthIndex = categories.indexOf(month);
            if (monthIndex !== -1) {
              revenueData[monthIndex] = apiRevenueData[index];
              profitData[monthIndex] = apiProfitData[index];
            }
          });
        } else {
          console.error('Failed to fetch finance data');
        }

        // Create the chart with updated data
        Highcharts.chart('chart-profit', {
          chart: {
            type: 'area',
          },
          accessibility: {
            description:
              'Image description: An area chart compares the revenue and profit per month.',
          },
          title: {
            text: 'Revenue Analytics with sales & profit (USD)',
            align: 'left',
          },
          xAxis: {
            lineColor: 'transparent',
            categories: categories,
          },
          yAxis: {
            labels: {
              format: '${value}',
              style: {
                fontSize: '12px',
                color: '#1D1D1D',
              },
            },
            lineColor: 'rgba(197, 212, 219, 0.4)',
            lineWidth: 1,
            gridLineWidth: 0,
            gridLineDashStyles: 'dot',
            title: {
              text: '',
            },
          },
          tooltip: {
            pointFormat:
              '{series.name} had a value of <b>${point.y:,.0f}</b><br/>in {point.x}',
          },
          credits: {
            enabled: false,
          },
          plotOptions: {
            area: {
              linecap: 'round',
              lineWidth: 2,
              marker: {
                enabled: false,
                symbol: 'circle',
                radius: 2,
                states: {
                  hover: {
                    enabled: true,
                  },
                },
              },
            },
          },
          series: [
            {
              name: 'Revenue',
              color: '#30A4DC26',
              lineColor: '#34C724',
              linecap: 'round',
              data: revenueData,
            },
            {
              name: 'Profit',
              color: 'rgba(16, 133, 183, 0.15)',
              lineColor: '#1085B7',
              borderWidth: 1,
              data: profitData,
            },
          ],
        } as any);
      },
      error: (error) => {
        console.error('API error', error);
      },
    });
  }

  fetchFinanceData(): void {
    this.dashboardService.getFinanceData().subscribe((res) => {
      const financeData = res?.data;
      this.totalAccount = financeData?.totalAccount;
      this.totalRevenue = financeData?.totalRevenue;
      this.totalExpense = financeData?.totalExpense;
      this.totalProfit = financeData?.totalProfit;
    });
  }

  fetchProjectStatus(): void {
    this.dashboardService.getProjectStatusData().subscribe((res) => {
      const projectStatusData = res?.data;
      this.totalProjectRecords = projectStatusData?.totalProject;
      this.activeProjectLength = projectStatusData?.active;
      this.completedProjectLength = projectStatusData?.completed;
      this.holdProjectLength = projectStatusData?.hold;
      this.totalProjects =
        this.completedProjectLength +
        this.activeProjectLength +
        this.holdProjectLength;

      // Calculate percentages
      this.successfulProjectPercentage =
        this.totalProjects > 0
          ? (this.completedProjectLength / this.totalProjects) * 100
          : 0;
      this.ongoingProjectPercentage =
        this.totalProjects > 0
          ? (this.activeProjectLength / this.totalProjects) * 100
          : 0;
      this.onHoldProjectPercentage =
        this.totalProjects > 0
          ? (this.holdProjectLength / this.totalProjects) * 100
          : 0;

      // Ensure a minimum width for visibility
      this.successfulProjectPercentage =
        this.successfulProjectPercentage > 0
          ? this.successfulProjectPercentage
          : 1;
      this.ongoingProjectPercentage =
        this.ongoingProjectPercentage > 0 ? this.ongoingProjectPercentage : 1;
      this.onHoldProjectPercentage =
        this.onHoldProjectPercentage > 0 ? this.onHoldProjectPercentage : 1;
    });
  }

  fetchOpenPositionLength(): void {
    this.dashboardService
      .getOpenPositionLength(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy
      )
      .subscribe((res) => {
        this.openPositionList = res.data.records;
        this.openPositionList.sort((a, b) => {
          return (
            new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime()
          );
        });
        this.totalOpenPositionRecords = res?.data?.count || 0;
      });
  }

  timeAgo(timestamp: string): string {
    const createdAt = new Date(timestamp);
    const now = new Date();
    const diff = Math.abs(now.getTime() - createdAt.getTime());

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days === 1 ? '1 day ago' : `${days} days ago`;
    }
    if (hours > 0) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    }
    if (minutes > 0) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    }
    return 'Just now';
  }

  getInitials(role: string): string {
    if (!role) return '';

    const firstChar = role.charAt(0);
    const indexOfFirstSpace = role.indexOf(' ');
    let secondChar = '';
    if (indexOfFirstSpace !== -1 && indexOfFirstSpace < role.length - 1) {
      secondChar = role.charAt(indexOfFirstSpace + 1);
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }

  goToProjects() {
    this.router.navigate(['./projects']);
  }

  goToOpenPositions() {
    this.router.navigate(['./open-positions']);
  }
}
