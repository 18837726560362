import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  baseUrl = environment.apiUrl;
  searchQuery = new BehaviorSubject<any>('');
  constructor(private apiservice: ApiService) {}

  emitSearchQuery(arg: String) {
    this.searchQuery.next(arg);
  }

  getFinanceData(): Observable<any> {
    const path = `${this.baseUrl}/dashboard-admin/finance-data`;
    return this.apiservice.get(path);
  }

  getProjectStatusData(): Observable<any> {
    const path = `${this.baseUrl}/dashboard-admin/project-status`;
    return this.apiservice.get(path);
  }

  getRevenuePerAccount(): Observable<any> {
    const path = `${this.baseUrl}/dashboard-admin/revenue-per-account`;
    return this.apiservice.get(path);
  }

  getRevenuePerTeam(): Observable<any> {
    const path = `${this.baseUrl}/dashboard-admin/revenue-per-team`;
    return this.apiservice.get(path);
  }

  getRevenueAndProfitPerMonth(): Observable<any> {
    const path = `${this.baseUrl}/dashboard-admin/profit-per-month`;
    return this.apiservice.get(path);
  }

  getOpenPositionLength(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: '' | 'ASC' | 'DESC' = ''
  ): Observable<any> {
    const path = `${this.baseUrl}/open-position/list?page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiservice.get(path);
  }
}
