<div class="main-content body-bg listing-wrapper">
  <div class="p-6">
    <div class="d-flex justify-between align-center mb-6">
      <div>
        <h4 class="mat-headline-5 strong text" style="margin-bottom: 0">
          Dashboard
        </h4>
        <p class="medium mat-body-1 text textXS">Manage Your Finance</p>
      </div>
    </div>
    <div class="body-bg dashboard-wrapper">
      <app-loader *ngIf="loading"></app-loader>
      <div class="dashboard-header-wrapper mb-4">
        <div class="row">
          <div class="col s12 m3 l3 card">
            <div class="account-info p-4 d-flex">
              <div
                class="icon-wrapper body-bg d-flex align-center justify-center mr-3"
              >
                <mat-icon class="stepper-icon" fontSet="material-icons-outlined"
                  >contacts</mat-icon
                >
              </div>
              <div class="w-full">
                <div class="d-flex w-full justify-between">
                  <div class="mb-3">
                    <span class="d-block grey-text textS fw-500 mb-2"
                      >TOTAL ACCOUNT</span
                    >
                  </div>
                </div>
                <span class="d-block text fw-500 mat-headline-5 word-break">{{
                  totalAccount
                }}</span>
              </div>
            </div>
          </div>
          <div class="col s12 m3 l3 card">
            <div class="account-info p-4 d-flex">
              <div
                class="icon-wrapper body-bg d-flex align-center justify-center mr-3"
              >
                <span class="material-symbols-outlined stepper-icon">
                  chart_data
                </span>
              </div>
              <div class="w-full">
                <div class="d-flex w-full justify-between">
                  <div class="mb-3">
                    <span class="d-block grey-text textS fw-500 mb-2"
                      >TOTAL REVENUE</span
                    >
                  </div>
                  <div>
                    <div id="chart-line-sec"></div>
                  </div>
                </div>
                <span class="d-block text fw-500 mat-headline-5 word-break"
                  >${{ totalRevenue | indianNumberFormat }}</span
                >
              </div>
            </div>
          </div>
          <div class="col s12 m3 l3 card style-card">
            <div class="account-info p-4 d-flex">
              <div
                class="icon-wrapper body-bg d-flex align-center justify-center mr-3"
              >
                <span class="material-symbols-outlined stepper-icon">
                  payments
                </span>
              </div>
              <div class="w-full">
                <div class="d-flex w-full justify-between">
                  <div class="mb-3">
                    <span class="d-block grey-text textS fw-500 mb-2"
                      >TOTAL EXPENSE</span
                    >
                  </div>
                  <div>
                    <div id="chart-line-tertiary"></div>
                  </div>
                </div>
                <span class="d-block text fw-500 mat-headline-5 word-break"
                  >${{ totalExpense | indianNumberFormat }}</span
                >
              </div>
            </div>
          </div>
          <div class="col s12 m3 l3 card style-card">
            <div class="account-info p-4 d-flex">
              <div
                class="icon-wrapper body-bg d-flex align-center justify-center mr-3"
              >
                <span class="material-symbols-outlined stepper-icon">
                  leaderboard
                </span>
              </div>
              <div class="w-full">
                <div class="d-flex w-full justify-between">
                  <div class="mb-3">
                    <span class="d-block grey-text textS fw-500 mb-2">
                      TOTAL PROFIT
                    </span>
                  </div>
                  <div>
                    <div id="line-tertiary"></div>
                  </div>
                </div>
                <span class="d-block text fw-500 mat-headline-5 word-break">
                  ${{ totalProfit | indianNumberFormat }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dashboard-content mb-4">
        <div class="row">
          <div class="col s12 m3 l3 project-info project">
            <div class="project-wrapper h-full">
              <div
                class="project-header px-4 py-3 d-flex justify-between align-center"
              >
                <span class="fw-500 text mat-subtitle-2">Project Status</span>
                <sft-button
                  [label]="'View All'"
                  [color]="'primary'"
                  [variant]="'default'"
                  [disable]="false"
                  [icon]="'home'"
                  [size]="'small'"
                  (buttonClick)="goToProjects()"
                ></sft-button>
              </div>

              <div class="project-content p-4">
                <div class="number-info mb-2">
                  <span class="d-block text mat-headline-5 mb-2">{{
                    totalProjectRecords
                  }}</span>
                </div>
                <div class="progress-bar-wrapper mb-6">
                  <span class="d-block fw-500 textXS grey-text mb-6"
                    >Total Number of Projects</span
                  >
                  <div class="progress-bar d-flex align-center">
                    <span
                      class="step"
                      [style.width.%]="successfulProjectPercentage"
                      style="background-color: #55a724"
                    ></span>
                    <span
                      class="step"
                      [style.width.%]="ongoingProjectPercentage"
                      style="background-color: #ff8800"
                    ></span>
                    <span
                      class="step"
                      [style.width.%]="onHoldProjectPercentage"
                      style="background-color: #f54a45"
                    ></span>
                  </div>
                </div>
                <div class="project-state-wrapper">
                  <div class="d-flex justify-between mb-6">
                    <div class="d-flex align-center">
                      <span class="dot green mr-2"></span>
                      <span class="text textXS">Successful Projects</span>
                    </div>
                    <span class="textXS grey-text"
                      >{{ completedProjectLength }} Project</span
                    >
                  </div>
                  <div class="d-flex justify-between mb-6">
                    <div class="d-flex align-center">
                      <span class="dot orange mr-2"></span>
                      <span class="text textXS">On Going Projects</span>
                    </div>
                    <span class="textXS grey-text"
                      >{{ activeProjectLength }} Project</span
                    >
                  </div>
                  <div class="d-flex justify-between mb-6">
                    <div class="d-flex align-center">
                      <span class="dot pink mr-2"></span>
                      <span class="text textXS">On Hold Projects</span>
                    </div>
                    <span class="textXS grey-text"
                      >{{ holdProjectLength }} Project</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col s12 m3 l6 project-data">
            <div class="project-wrapper h-full">
              <div
                class="project-header px-4 py-3 d-flex justify-between align-center"
              >
                <span class="fw-500 text mat-subtitle-2"
                  >Revenue Analytics
                </span>
              </div>
              <div class="analytics-wrapper p-6">
                <div>
                  <div id="chart-xy-line"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col s12 m3 l3 project-info">
            <div class="project-wrapper h-full">
              <div
                class="project-header px-4 py-3 d-flex justify-between align-center"
              >
                <span class="fw-500 text mat-subtitle-2">Open Position</span>
                <sft-button
                  [label]="'View All'"
                  [color]="'primary'"
                  [variant]="'default'"
                  [disable]="false"
                  [icon]="'home'"
                  [disableRipple]="false"
                  [size]="'small'"
                  (buttonClick)="goToOpenPositions()"
                ></sft-button>
              </div>
              <div class="project-content finance-info-wrapper pt-4 pb-4 pl-4">
                <div class="number-info mb-2">
                  <span class="d-block text mat-headline-5 mb-2">{{
                    totalOpenPositionRecords
                  }}</span>
                  <span class="textXS fw-500 grey-text"
                    >Total Open Position</span
                  >
                </div>
                <div class="info-container pr-4">
                  <div
                    class="d-flex justify-between mb-4"
                    *ngFor="let item of openPositionList"
                  >
                    <div class="d-flex">
                      <div
                        class="avatar-sm d-flex mr-2 align-center justify-center primary-light-text textXS primary"
                      >
                        {{ getInitials(item.op_role) }}
                      </div>
                      <div>
                        <span class="d-block text textXS">{{
                          item?.role
                        }}</span>
                        <span class="textXS grey-text d-flex align-center">
                          <span
                            class="project-avatar-detail"
                            title="{{ item?.ac_name }}"
                            >{{ item?.ac_name }}</span
                          >
                          <span class="grey-dot d-block mr-2 ml-2"></span
                          ><span>
                            <span>{{ timeAgo(item.op_modified_at) }}</span>
                          </span></span
                        >
                      </div>
                    </div>
                    <div class="text-end">
                      <span class="d-block textXS text">{{
                        item?.op_start_date | date : "dd MMM yyyy" || "--"
                      }}</span>
                      <span
                        class="d-block textXS text name-detail"
                        title="{{ item?.pr_name }}"
                        >{{ item?.pr_name }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dashboard-content mb-4">
        <div class="row">
          <div class="col s12 m3 l6">
            <div class="project-wrapper h-full">
              <div
                class="project-header px-4 py-3 d-flex justify-between align-center"
              >
                <span class="fw-500 text mat-subtitle-2"
                  >Revenue Analytics
                </span>
              </div>
              <div class="analytics-wrapper">
                <div class="p-6">
                  <div id="chart-horizontal-line"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col s12 m3 l6">
            <div class="project-wrapper h-full">
              <div
                class="project-header px-4 py-3 d-flex justify-between align-center"
              >
                <span class="fw-500 text mat-subtitle-2"
                  >Revenue vs Profit
                </span>
              </div>
              <div class="analytics-wrapper">
                <div class="p-6">
                  <div id="chart-profit"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
