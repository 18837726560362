import { NgModule } from '@angular/core';
import { LayoutModule } from '../../core/layouts/layout/layout.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { CommonModule } from '@angular/common';
import { SftButtonModule } from 'src/stories/components/button/button.module';
import { SidebarModule } from 'src/app/admin-portal/core/layouts/sidebar/sidebar.module';
import { AngularMaterialModule } from 'src/shared/modules/angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/shared/modules/shared/shared.module';
import { LoaderComponent } from 'src/shared/components/loader/loader.component';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    LoaderComponent,
    LayoutModule,
    CommonModule,
    SidebarModule,
    SftButtonModule,
    DashboardRoutingModule,
    FormsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [DashboardComponent],
})
export class DashboardModule {}
